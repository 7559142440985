import React, { createContext, useEffect, useCallback, useMemo } from "react"
import { hotjar } from "react-hotjar"
import * as FullStory from "@fullstory/browser"

export const AnalyticsContext = createContext()

export default function AnalyticsProvider({ children }) {
  useEffect(() => {
    if (hotjar.initialized()) return

    initializeHotJar()
  }, [])

  useEffect(() => {
    if (FullStory.isInitialized()) return

    initFullStory()
  }, [])

  function initializeHotJar() {
    try {
      hotjar.initialize(1395454, 6)
    } catch (e) {
      console.log(e)
    }
  }

  function initFullStory() {
    FullStory.init({ orgId: "o-1EA01Q-na1" })
  }

  const trackPage = useCallback(pathname => {
    if (!hotjar.initialized()) return

    hotjar.stateChange(pathname)
  }, [])

  const value = useMemo(() => {
    return {
      trackPage,
    }
  }, [trackPage])

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  )
}
