import React from "react"
import { ThemeProvider } from "@material-ui/styles"
import CalendyProvider from "./context/calendy-context"
import AnalyticsProvider from "./context/analytics-context"
import theme from "./mui-theme"

const Providers = ({ element }) => {
  return (
    <AnalyticsProvider>
      <ThemeProvider theme={theme}>
        <CalendyProvider>{element}</CalendyProvider>
      </ThemeProvider>
    </AnalyticsProvider>
  )
}

export default Providers
