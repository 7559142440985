import React, { createContext, useCallback, useMemo } from "react"
import { Constants } from "../constants"

export const CalendyContext = createContext()

export default function CalendyProvider({ children }) {
  const { calendarUrl } = Constants

  const initCalendy = useCallback(() => {
    global.Calendly.initPopupWidget({
      url: calendarUrl,
    })
  }, [])

  const value = useMemo(() => {
    return {
      initCalendy,
    }
  }, [initCalendy])

  return (
    <CalendyContext.Provider value={value}>
      {children}

      <script
        src="https://calendly.com/assets/external/widget.js"
        type="text/javascript"
      />
    </CalendyContext.Provider>
  )
}
