import "normalize.css/normalize.css"
import "./src/css/main.css"
import "./src/css/calendly.css"
import "./src/css/404.css"
import "./node_modules/reveal.js/dist/reveal.css"
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import providersWrapper from "./src/providers-wrapper"
export const wrapRootElement = providersWrapper
