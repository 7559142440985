const Constants = {
  calendarUrl:
    "https://calendly.com/astraload-ceo/30min?hide_event_type_details=1&primary_color=ffa600",
  quoteLink:
    "https://docs.google.com/forms/d/e/1FAIpQLScvOuYjg2OaomMJd8JwkG_ieyhUIWAtDvScUPq1fxnhnaQBkw/viewform",

  navigationLinks: [
    {
      to: "/astraload-perf-team/",
      children: "Perf team",
      isGatsbyLink: true,
    },
    {
      to: "/astraload-frontend",
      children: "Frontend program",
      isGatsbyLink: true,
    },
    {
      to: "/astraload-software-development",
      children: "Software Development",
      isGatsbyLink: true,
    },
    {
      to: "/astraload-saas/",
      children: "SaaS",
      isGatsbyLink: true,
    },
    {
      to: "https://blog.astraloadhq.com/",
      children: "Blog",
      isGatsbyLink: false,
    },
  ],

  tools: [
    "tool1",
    "tool2",
    "tool3",
    "tool4",
    "tool5",
    "tool6",
    "tool7",
    "tool8",
    "tool9",
    "tool10",
    "tool11",
    "tool12",
  ],

  companies: [
    { name: "techGC", url: "https://techgc.co" },
    { name: "knotel", url: "https://knotel.com" },
    { name: "knotable", url: "https://knotable.com" },
  ],

  performanceImage: [
    {
      title: "Initial research",
      description:
        "First, we carefully listen to you to identify" +
        " problems you have. Then we assess your" +
        " web application to learn about its tech " +
        "stack and server setup. We will also run " +
        "few simple tests to identify trivial " +
        "performance issues should they exist.",
    },

    {
      title: "Load testing environment preparation ",
      description:
        "Then we’ll focus on creating conditions for " +
        "load testing: do we need a separate testing " +
        "environment, how to create test users if we " +
        "need, how to reset database state, how to " +
        "accurately emulate your users behavior.",
    },

    {
      title: "Performance improvement loop",
      description:
        "1. Run tests & collect metrics\n" +
        "2. Study the metrics and find bottlenecks \n" +
        "3. Fix the bottlenecks and repeat",
    },
  ],
  whatWeDoSection: {
    title: "What we do",
    description: `We focus on performance optimization of web apps - particularly single page
    applications built with Meteor and GraphQL.`,
  },
  whatTeamWillDo: {
    title: "What team is actually going to do",
    list: [
      "Set up a design system. Build a component library in Storybook.",
      "Train existing design and development teams or seed new ones.",
      "Create designs and set high standards of UI components quality.",
      "Develop frontend and put it under strict process of design review and automated screenshot and unit testing."
    ]
  },
  singlePageSupport: {
    title: `Single page applications first
     class support`,
    description: `It is especially beneficial for single page applications built with technologies like Meteor and
     GraphQL as you cannot predict UX only taking into consideration results of REST API load testing or
      initial page load metrics.`,
    description2: `Astraload tracks users paths, websocket and GraphQL, resources origins
    to help you see where UX may have been degraded.`,
  },
  saasIntelligantAssiatant: {
    title: "Intelligent assistant",
    description: `Simple performance drawbacks are highlighted by a built-in intelligent assistant. It covers more scenarios on every new release. More complex cases will require attention of performance optimization professionals.`,
  },
  howWeDifferent: {
    title: "How we’re different",
    description: `We’re not limiting optimization by ‘page load’ or ‘API metrics’, on the contrary we consider the full
    spectrum of UX performance issues that might affect users of your website.`,
  },
  benefits: {
    title: "What you will get",
    items: [
      {
        key: "figma",
        imageName: "benefit1",
        text: "UI designs consolidated in a design system and stored in Figma",
      },
      {
        key: "storybook",
        imageName: "benefit2",
        text: "Simple and complex UI components developed in Storybook",
      },
      {
        key: "components",
        imageName: "benefit3",
        text:
          "Robust processes for handing designs and code and ensuring artifacts quality over time",
      },
      {
        key: "training",
        imageName: "benefit4",
        text: "Training for your existing or establishing team",
      },
    ],
  },
  ourTools: {
    title: "We keep our eyes open",
    description: `Before we created our own tool, we built solid experience applying other 
    industry standard tools for digging into web apps internals and discovering
    hidden bottlenecks in frontend, backend, database or in deployment setup`,
  },
  program: {
    title: "Program structure",
    description: `A pre-built 9-step program to establish a design system and effective development
    and testing processes that will be executed by Astraload team`,
  },
  callToActionBlock: {
    title: `Want to be sure in your webapp
    performance?`,
    description: `Either your webapp is already live and you already experience performance issues or maybe you’re
    just planning to make sure your website will handle more intense load - we can help you. There are
    many ways get in touch with us.`,
  },
  saasCallToActionBlock: {
    title: `Ready to improve your site design and frontend development processes?`,
    description: `Either you already have a design system or maybe you're just planning to introduce it - we can help you. 
Get in touch with us to start`,
    buttonTitle: 'Tell about your project'
  },
  dsstCallToActionBlock: {
    title: `Book Astraload
    frontend team now`,
    description: `Astraload is a small team of seasoned professionals, all our services are highly exclusive`,
    buttonTitle: "Book the team"
  },
  servicesContactCallToActionBlock: {
    title: `Let’s talk about
      business`,
    description: `Astraload is small team of seasoned professionals, all our services are highly exclusive`,
    buttonTitle: "Tell about your project"
  },
  softwareDevelopmentCallToActionBlock: {
    title: `Got any project or think about a new one?`,
    description: `Either you just have a project idea or you would like to put effort into existing project - we can help you. There are many ways to get in touch with us`,
    buttonTitle: "Tell about your project"
  },
  perfTeamCallToActionBlock: {
    title: "Want to be sure in your webapp performance?",
    description: "Either your web application is already running and you're already experiencing performance issues," +
      " or you're planning to make sure your site can handle a more intensive load - we can help you",
    buttonTitle: "Book the team"
  },
  ourProjectsBlock: {
    title: "Projects",
  },
  feedBackBlock: {
    title: "Join a good company of our clients",
    feedbacks: [
      {
        logoName: "selfleaders",
        link: "https://selfleaders.com",
        clientData: {
          name: "Dominic Von Martens",
          company: "Product Manager @ Selfleaders",
        },
        feedback:
          "«In short order Astraload team managed to achieve what previously seemed absolutely impossible! Thanks to their involvement we're now able to implement our most optimistic strategy»",
      },
      {
        logoName: "rabbit",
        link: "https://selfleaders.com",
        clientData: {
          name: "Max Hodges",
          company: "Founder/director @ White Rabbit Japan",
        },
        feedback:
          "«Hey btw if you guys need help with performance issues these guys are good. @eluck and his consulting services team helped identify and fix some rogue publications that there causing occasional crashes that were driving us nuts.»",
      },
      {
        logoName: "tgc",
        link: "https://selfleaders.com",
        clientData: {
          name: "Harrison Hunter",
          company: "CTO MaestroQA",
        },
        feedback:
          "«What I truly appreciate with the team at Astraload is not just a high degree of skill and ownership but the desire to evolve and improve. The team is always thinking about how they can level up themselves and our collaboration, and pushes us to improve our practices to achieve the best outcomes»",
      },
    ],
  },
  servicesFeedbacks: {
    title: "Clients about us",
    feedbacks: [
      {
        logoName: "servicesFeedback1",
        name: "Max Hodges",
        company: "Founder/director @ White Rabbit Japan",
        feedback: "«Hey btw if you guys need help with performance issues these guys are good. @eluck and his" +
          " consulting services team helped identify and fix some rogue publications that there causing occasional crashes that were driving us nuts.»",
      },
      {
        logoName: "servicesFeedback2",
        name: "Harrison Hunter",
        company: "CTO MaestroQA",
        feedback: "«What I truly appreciate with the team at Astraload is not just a high degree of skill and ownership but the desire to evolve and improve. The team is always thinking about how they can level up themselves and our collaboration, and pushes us to improve our practices to achieve the best outcomes»",
      },
      {
        logoName: "servicesFeedback3",
        name: "Dominic Von Martens",
        company: "Product Manager @ Selfleaders",
        feedback: "«In short order Astraload team managed to achieve what previously seemed absolutely impossible! Thanks to their involvement we're now able to implement our most optimistic strategy»",
      },

    ]
  },
  advantages: {
    title: "Astraload SaaS",
    description: `Specifically for that, we’ve built Astraload SAAS - a UX
             testing tool working on a scale of thousands of users with first class
             Meteor and GraphQL data layers support.`,
  },
  saasHeader: {
    title: "Astraload SaaS",
    description: `A load testing and performance analytics tool with first 
    class Meteor and GraphQL data layers support.

    Our product runs thousands of emulated users and
     gathers various statistics and present it in readable and
      understandable form`,
    backgroundColor: "gray",
    desktopButtonText: "Let’s optimize your webapp now",
    mobileButtonText: "Let’s optimize your webapp",
  },
  saasWhatItDoes: {
    title: "What it does",
    description: `Its runs thousands of emulated users and gathers various statistics from every
    single of them to present it in readable and understandable form.`,
  },
  saasTextBlock: {
    title: "Traditional load testing tools just generate API load and present very general data like API response time or page load time. And they miss tremendous amount of information about user experience that can be extracted and analyzed"
  },
  dsstHeader: {
    title: 'Design System, Storybook and Testing',
    description: `A vetted team of design, development and facilitation professionals who will scale your frontend development with state-of-the art tooling, robust processes and methodical coaching by executing a 12-week supercharged enhancement program`,
    desktopButtonText: "Book the team now!",
    mobileButtonText: "Book the team now!",
    backgroundColor: "white",
    footerBg: "dark",
  },
  perfTeamHeader: {
    title: `Astraload perf team`,
    description: `Meet the crew who can scale your single page application to handle millions of monthly users. We focus on performance optimization of web apps — particularly single page applications built with Meteor and GraphQL`,
    desktopButtonText: "Optimize your webapp",
    mobileButtonText: "Optimize your webapp",
  },
  softwareDevelopmentHeader: {
    title: `Full cycle software
      development`,
    description: `Astraload designs, implements and maintains desktop, web and mobile
      software. Check our portfolio`,
    desktopButtonText: "Tell us about your project",
    mobileButtonText: "Tell us about your project",
  },
  keyWords:
    "load testing tools, load testing software, load testing framework, load testing service, load testing website, load testing applications, load and performance testing, performance optimization, stress and load testing, load testing for websites, performance analytics tool, meteor, graphql, performance analytics tool, high performance analytics, astraload, meteor and graphql, scale single page application, performance optimization of web apps, UX performance, astraload team, ux performance testing, node js performance, node js performance analysis, node js performance testing, performance team, team performance analysis, perf team, meteor optimization",
  qaBlock: {
    title: "Q&A",
    items: [
      {
        id: "item1",
        title: "Can the team drop training part?",
        summary: "If there's no one to teach, we can skip this part.",
      },
      {
        id: "item2",
        title: "Can the team initiate a greenfield project?",
        summary:
          "Yes, no problem. We can do fullstack development including devops as well.",
      },
      {
        id: "item3",
        title: "Can the team continue working after 12 weeks?",
        summary:
          "Yes, up to some point. We will gently upgrade our relationship model to a custom project support.",
      },
    ],
  },
  ourProjects: [
    {
      imgName: "questAppImg",
      title: "Quest App",
      description: "A cyberpunk quest story implemented in web. We let our imagination complete freedom and got a piece of art",
      link: "https://quest.astraloadhq.com/"
    },
    {
      imgName: "astraloadAppImg",
      title: "Load testing and analytics service",
      description: "The project is intended to emulate horde of users visiting a target website to gather website performance information, analyse it and use it for project enhancement",
      link: "https://app.astraloadhq.com/"
    },
    {
      imgName: "screenCaptureChromeExtension",
      title: "Screen capture Chrome extension",
      description: "A Chrome extension that's part of a US startup software suite. It is used to capture screen recordings of quality assurance teams.",
      link: ""
    }
  ],

  technologies: [
    {
      title: "Development",
      description: "Node.js, Javascript, Typescript, SQL, NoSQL (MongoDB), Screenshot-testing, Unit-testing, Integration-testing, Load-testing, UI componentization, React, Wallaby.js",
      logos: ["techNode", "techReact", "techJS", "techTS", "techSQL", "techMongo", "techUnion"]
    },
    {
      title: "Design",
      description: "We have extensive experience in Web-design, Illustration design, UI-design, Design systems, SWAG design",
      logos: ["techFigma", "techPS", "techAI", "techWebflow"]
    },
    {
      title: "Devops",
      description: "AWS cloud (EC2, S3, Cloudfront, SQS, etc.), DO & Hetzner clouds, Web-related (Routing, High-load, Backups), Continuous Integration (Github Action, CircleCI), Linux (maintaining & fine-tuning)",
      logos: ["techAws", "techGithub", "techCircleCI", "techLinux", "techDocker", "techHetzner"]
    },
    {
      title: "Project management",
      description: "Agile team management (Kanban & SCRUM), Software Requirements Expert (aka Business Analyst), Facilitation expert",
      logos: ["techJira", "techIcon1", "techIcon2"]
    },
    {
      title: "Project analytics",
      description: "Hotjar (user behavior tracking), Google Analytics (website performance), Mixpanel (funnels, dashboards)",
      logos: ["techGA", "techHotjar", "techMixpanel"]
    },
  ],
  ourServices: {
    title: "Our services",
    description: "You can rely on us to cover any product development need you have",
  },

  services: [
    {
      bgName: "MeteorIcon",
      title: "Meteor development",
      list: ["Top expertise on the market", "Build, scale or maintain Meteor-based projects",
        "Improve architecture and stability", "Seamlessly migrate to Express or GraphQL"],
      linkText: "Hire team",
      link: "https://docs.google.com/forms/d/e/1FAIpQLScvOuYjg2OaomMJd8JwkG_ieyhUIWAtDvScUPq1fxnhnaQBkw/viewform"
    },
    {
      bgName: "CodeIcon",
      title: "Full cycle software development",
      list: ["Requirements management", "Product analytics", "Web and mobile development", "Devops and production maintenance", "SEO and promotion"],
      linkText: "Learn more",
      link: "/astraload-software-development"
    },
    {
      bgName: "CircleIcon",
      title: "Web development",
      list: ["Node.js or Express.js", "React or Vue", "Nice frontend", "Performant backend", "Robust devops", "Elegant architecture"],
      linkText: "Hire team",
      link: "https://docs.google.com/forms/d/e/1FAIpQLScvOuYjg2OaomMJd8JwkG_ieyhUIWAtDvScUPq1fxnhnaQBkw/viewform"
    },
    {
      bgName: "SystemIcon",
      title: "Frontend program",
      list: [],
      text: "A vetted team of design, development and facilitation professionals who will scale your frontend development with state-of-the art tooling, robust processes and methodical coaching",
      linkText: "Learn more",
      link: "/astraload-frontend"
    },
  ],
  ourBlogBlock: {
    title: "Sharing projects and our experience"
  },
  blogPosts: [
    {
      previewImg: "servicesBlogPreview1",
      title: "Does a design system introduction require a complete UI rewrite?",
      date: "09.14.2022",
      link: "https://blog.astraloadhq.com/2022-09-14/does-a-design-system-adoption-require-a-complete-ui-rewrite/",
    },
    {
      previewImg: "servicesBlogPreview2",
      title: "A short introduction to \"Astraload frontend – design system, Storybook and testin...",
      date: "09.02.2022",
      link: "https://blog.astraloadhq.com/2022-08-17/a-short-introduction-to-Astraload-frontend/",
    },
    {
      previewImg: "servicesBlogPreview3",
      title: "Hire Astraload frontend team",
      date: "10.28.2022",
      link: "https://docs.google.com/forms/d/e/1FAIpQLScvOuYjg2OaomMJd8JwkG_ieyhUIWAtDvScUPq1fxnhnaQBkw/viewform",
    },
  ],
  meteorExpertiseBlock: {
    title: "Top Meteor expertise on the market",
    description: "By professionals who form current Astraload crew"
  },
  ourJourneyBlock: {
    title: "Join our journey"
  },
  ourJourney: [
    {
      icon: "TwitterIcon",
      text: "Get all updates immediately",
      linkText: "Follow us on Twitter",
      link: "https://twitter.com/astraload"
    },
    {
      icon: "LinkedinIcon",
      text: "Follow our business, read the articles we write, and learn about our educational courses and seminars",
      linkText: "Follow us on Linkedin",
      link: "https://www.linkedin.com/company/astraload"
    },
    {
      icon: "EmailIcon",
      text: "Receive consolidated updates and membership offers - discounts on our educational courses and seminars",
      linkText: "",
      link: ""
    },
  ]
}

export { Constants }
