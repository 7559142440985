// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-astraload-frontend-jsx": () => import("./../../../src/pages/astraload-frontend.jsx" /* webpackChunkName: "component---src-pages-astraload-frontend-jsx" */),
  "component---src-pages-astraload-frontend-presentation-jsx": () => import("./../../../src/pages/astraload-frontend-presentation.jsx" /* webpackChunkName: "component---src-pages-astraload-frontend-presentation-jsx" */),
  "component---src-pages-astraload-frontend-presentation-ru-jsx": () => import("./../../../src/pages/astraload-frontend-presentation-ru.jsx" /* webpackChunkName: "component---src-pages-astraload-frontend-presentation-ru-jsx" */),
  "component---src-pages-astraload-perf-team-jsx": () => import("./../../../src/pages/astraload-perf-team.jsx" /* webpackChunkName: "component---src-pages-astraload-perf-team-jsx" */),
  "component---src-pages-astraload-saas-jsx": () => import("./../../../src/pages/astraload-saas.jsx" /* webpackChunkName: "component---src-pages-astraload-saas-jsx" */),
  "component---src-pages-astraload-software-development-jsx": () => import("./../../../src/pages/astraload-software-development.jsx" /* webpackChunkName: "component---src-pages-astraload-software-development-jsx" */),
  "component---src-pages-important-ones-privacy-jsx": () => import("./../../../src/pages/important-ones-privacy.jsx" /* webpackChunkName: "component---src-pages-important-ones-privacy-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */)
}

